const actions = {
  ON_COMPANY_DATA_CHANGE: 'campaign/ON_COMPANY_DATA_CHANGE',
  ADD_SMILE_TO_SMS_BODY: 'campaign/ADD_SMILE_TO_SMS_BODY',
  CREATE_COMPANY_REQUEST: 'campaign/CREATE_COMPANY_REQUEST',
  CREATE_COMPANY_SUCCESS: 'campaign/CREATE_COMPANY_SUCCESS',
  CREATE_COMPANY_FAILURE: 'campaign/CREATE_COMPANY_FAILURE',

  LOAD_CAMPAIGN_REQUEST: 'campaign/LOAD_CAMPAIGN_REQUEST',
  LOAD_CAMPAIGN_SUCCESS: 'campaign/LOAD_CAMPAIGN_SUCCESS',
  LOAD_CAMPAIGN_FAILURE: 'campaign/LOAD_CAMPAIGN_FAILURE',

  REMOVE_CAMPAIGN_REQUEST: 'campaign/REMOVE_CAMPAIGN_REQUEST',
  REMOVE_CAMPAIGN_SUCCESS: 'campaign/REMOVE_CAMPAIGN_SUCCESS',
  REMOVE_CAMPAIGN_FAILURE: 'campaign/REMOVE_CAMPAIGN_FAILURE',

  START_CAMPAIGN_REQUEST: 'campaign/START_CAMPAIGN_REQUEST',
  START_CAMPAIGN_SUCCESS: 'campaign/START_CAMPAIGN_SUCCESS',
  START_CAMPAIGN_FAILURE: 'campaign/START_CAMPAIGN_FAILURE',

  GET_COMPANY_REQUEST: 'companies/GET_COMPANY_REQUEST',
  GET_COMPANY_SUCCESS: 'companies/GET_COMPANY_SUCCESS',
  GET_COMPANY_FAILURE: 'companies/GET_COMPANY_FAILURE',

  UPDATE_USERS_REQUEST: 'companies/UPDATE_USERS_REQUEST',
  UPDATE_USERS_SUCCESS: 'companies/UPDATE_USERS_SUCCESS',
  UPDATE_USERS_FAILURE: 'companies/UPDATE_USERS_FAILURE',

  GET_CAMPAIGN_STATISTICS_REQUEST: 'campaign/GET_CAMPAIGN_STATISTICS_REQUEST',
  GET_CAMPAIGN_STATISTICS_SUCCESS: 'campaign/GET_CAMPAIGN_STATISTICS_SUCCESS',
  GET_CAMPAIGN_STATISTICS_FAILURE: 'campaign/GET_CAMPAIGN_STATISTICS_FAILURE',
};

export default actions;
