const actions = {
  FETCH_POOLS_BY_RUN_ID_REQUEST: 'pools/FETCH_POOLS_BY_RUN_ID_REQUEST',
  FETCH_POOLS_BY_RUN_ID_SUCCESS: 'pools/FETCH_POOLS_BY_RUN_ID_SUCCESS',
  FETCH_POOLS_BY_RUN_ID_FAILURE: 'pools/FETCH_POOLS_BY_RUN_ID_FAILURE',

  FETCH_POOLS_BY_COMPANY_ID_REQUEST: 'pools/FETCH_POOLS_BY_COMPANY_ID_REQUEST',
  FETCH_POOLS_BY_COMPANY_ID_SUCCESS: 'pools/FETCH_POOLS_BY_COMPANY_ID_SUCCESS',
  FETCH_POOLS_BY_COMPANY_ID_FAILURE: 'pools/FETCH_POOLS_BY_COMPANY_ID_FAILURE',

  FETCH_ALL_POOLS_REQUEST: 'pools/FETCH_ALL_POOLS_REQUEST',
  FETCH_ALL_POOLS_SUCCESS: 'pools/FETCH_ALL_POOLS_SUCCESS',
  FETCH_ALL_POOLS_FAILURE: 'pools/FETCH_ALL_POOLS_FAILURE',

  PUBLISH_POOL_REQUEST: 'pools/PUBLISH_POOL_REQUEST',
  PUBLISH_POOL_SUCCESS: 'pools/PUBLISH_POOL_SUCCESS',
  PUBLISH_POOL_FAILURE: 'pools/PUBLISH_POOL_FAILURE',

  FETCH_RESULT_LIST_REQUEST: 'pools/FETCH_RESULT_LIST_REQUEST',
  FETCH_RESULT_LIST_SUCCESS: 'pools/FETCH_RESULT_LIST_SUCCESS',
  FETCH_RESULT_LIST_FAILURE: 'pools/FETCH_RESULT_LIST_FAILURE',

  UPDATE_POOL_RESULT_REQUEST: 'pools/UPDATE_POOL_RESULT_REQUEST',
  UPDATE_POOL_RESULT_SUCCESS: 'pools/UPDATE_POOL_RESULT_SUCCESS',
  UPDATE_POOL_RESULT_FAILURE: 'pools/UPDATE_POOL_RESULT_FAILURE',
};

export default actions;
